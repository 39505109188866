import styled from 'styled-components';

export const StepTimeLineArea = styled.section`
  margin: 90px auto;
  width: 360px;
  border-left: 2px solid #ccc;
  padding: 0 20px 0 30px;

  div{
    padding: 10px 25px;
    font-size: 16px;
    line-height: 1.7;
    position: relative;
    height: 110px;
    margin-bottom: 20px;
  }

  div:before{
    content: '';
    display: block;
    width: 10px;
    height:10px;
    border-radius: 50%;
    background-color: #000;
    border: 3px solid #ddd;
    position: absolute;
    top: 10px;
    left: -46px;
  }

  div:after{
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent #000 transparent transparent;
    border-width: 8px;
    position: absolute;
    left: -17px;
    top: 10px;
  }

  div h2{
    margin: 0;
    text-transform: uppercase;
    font-family: merienda;
  }

  div h3{
    margin: 0;
    font-family: poppins;
  }

  div h4{
    margin: 0;
    font-family: poppins;
  }
`;

export const AsideTypography = styled.h5`
  font-size: 25px;
  margin: 15px 0;
`;


export const EstornoTotal = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 8px;
  background-color: tomato;
  display: flex;
  justify-content:center;
  align-items:center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 15px;
  transition: 0.2s ease-in-out;
  outline: none;
  border:none;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  margin-bottom: 10px;

  &:hover{
    opacity: 0.7;
    transition: 0.2s ease-in-out;
  }
`;

export const EstornoParcial = styled.button`
  width: 170px;
  height: 50px;
  border-radius: 8px;
  background-color: tomato;
  padding: 0 10px;
  display: flex;
  justify-content:center;
  align-items:center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 15px;
  transition: 0.2s ease-in-out;
  outline: none;
  border:none;
  font-weight: bold;
  cursor: pointer;
  color: #fff;

  &:hover{
    opacity: 0.7;
    transition: 0.2s ease-in-out;
  }
`;

export const TransOptions = styled.section`
  margin: 20px 10px;
  display: flex;
  justify-content:flex-end;
  align-items:center;
  flex-direction: column;
`;

export const Line = styled.div`
  width: 3px; 
  height: ${({height}) => `${height * 70}px`};
  background-color: #ccc; 
  margin-right: 0px;

  @media screen and (max-width: 1535px){
    height: ${({height}) => `${height * 100}px`};
  }
`;

export const Seta = styled.div`
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #ccc;
  margin-right: 10px;
`;

export const Date = styled.span`
  
`;

export const Status = styled.span`
  font-size: 15px;
`;

export const TimeLine = styled.div`
  display: flex; 
  justify-content:center; 
  align-items: center;
`;

export const Dates = styled.div`
  margin-right: 40px;
`;

export const StatusBody = styled.div`
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: flex-start;
`;