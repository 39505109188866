import axios from 'axios';
import {useCallback, useEffect, useState} from 'react' //eslint-disable-line
import { useDispatch } from 'react-redux';
import { TransactionActions } from 'src/pages/Transactions/store/reducer';
import { UserActions } from 'src/pages/User/store/reducer';

export function useGetSelers(){
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const handleSelers = useCallback(async () => {
    dispatch(UserActions.initLoading());
    try{

      const config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/clients`, config);
      const salesChannel = response?.data;

      dispatch(TransactionActions.asyncSellers(salesChannel));
      dispatch(UserActions.endLoading());
      return response.data;
    } catch(error){
      console.log(error)
      dispatch(UserActions.endLoading());
    }
  }, [])

  return handleSelers
}