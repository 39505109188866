export const UserActionTypes = {
  ASYNC_USER_EDIT: '@auth/ASYNC_USER_EDIT',
  ASYNC_PERMISSIONS: '@auth/ASYNC_PERMISSIONS',
  INIT_LOADING: '@auth/INIT_LOADING',
  END_LOADING: '@auth/END_LOADING',
  INIT_USER_LOADING: '@auth/INIT_USER_LOADING',
  END_USER_LOADING: '@auth/END_USER_LOADING',
  ASYNC_USER: '@auth/ASYNC_USER',
};

const INITIAL_STATE = {
  userEdit: {},
  permissions: [],
  user: {},
  loading: false,
  userLoading: false,
};

export class UserActions {

  static asyncUserEdit = (payload) => ({type: UserActionTypes.ASYNC_USER_EDIT, payload: payload});
  static asyncPermissions = (payload) => ({type: UserActionTypes.ASYNC_PERMISSIONS, payload: payload});
  static initLoading = () => ({type: UserActionTypes.INIT_LOADING, payload: true});
  static endLoading = () => ({type: UserActionTypes.END_LOADING, payload: false});
  static initUserLoading = () => ({type: UserActionTypes.INIT_USER_LOADING, payload: true});
  static endUserLoading = () => ({type: UserActionTypes.END_USER_LOADING, payload: false});
  static asyncUser = (payload) => ({type: UserActionTypes.ASYNC_USER, payload: payload});
}

export class UserSelectors {

  static getUserEdit({user}) {
    return user.userEdit;
  }

  static getPermissions({user}) {
    return user.permissions;
  }

  static getLoading({user}) {
    return user.loading;
  }

  static getUserLoading({user}) {
    return user.userLoading;
  }

  static getUser({user}) {
    return user.user;
  }


}

export function reducer(state = INITIAL_STATE, {type, payload}) {
  switch (type) {
  case UserActionTypes.ASYNC_USER_EDIT:
    return {...state, userEdit: payload};
  case UserActionTypes.ASYNC_PERMISSIONS:
    return {...state, permissions: payload};
    case UserActionTypes.INIT_LOADING:
      return {...state, loading: payload};
    case UserActionTypes.END_LOADING:
      return {...state, loading: payload};
    case UserActionTypes.INIT_USER_LOADING:
      return {...state, userLoading: payload};
    case UserActionTypes.END_USER_LOADING:
      return {...state, userLoading: payload};
    case UserActionTypes.ASYNC_USER:
      return {...state, user: payload};
  default:
    return state;
  }
}
