import styled from 'styled-components';

export const ContainerFilter = styled.div`
    display: flex;
    justify-content: center; 
    align-items: flex-start; 
    flex-direction: column; 
    margin-top: 15px;
`;

export const ContainerLabel = styled.span`

`;

export const ContainerItem = styled.div`
    display: flex;
    justify-content: center; 
    align-items: center;
`;