import React from 'react'
import { LoadingContainer, LoadingComp } from './Loading.Elements'

const Loading = () => {
  return (
      <LoadingContainer>
          <LoadingComp />
      </LoadingContainer>
  )
}

export default Loading
