import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    outline: none;
    list-style: none;
  }

  body {
    background: #f5f7fe;
    position: relative;
  }
`;

export default GlobalStyle;
