import React from 'react'

import {
    ButtonContainer,
    ButtonFormat
} from './Button.Elements'

const Button = ({title, widthT, ...props}, loading) => {
  return (
      <ButtonContainer widthT={widthT}>
          <ButtonFormat loading={loading} {...props}>
              {title}
          </ButtonFormat>
      </ButtonContainer>
  )
}

export default Button
