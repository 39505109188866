import styled from 'styled-components';

export const ButtonContainer = styled.section`
  width: ${({widthT}) => `${widthT}px`};
  padding-left: 10px;
`;

export const ButtonFormat = styled.button`
    border-radius: 4px;
    background-color: ${({bgColor}) => bgColor || '#445278'};
    font-weight: 800;
    text-align: center;
    color: #ffffff;
    font-size: 20px;
    text-transform: uppercase;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    border: 0;
    margin-top: ${({marginT}) => `${marginT}px`};
    transition: 0.2s ease-in-out;
    opacity: ${({loading}) => loading && '0.5'};

    &:hover{
        opacity: 0.8;
        transition: 0.2s ease-in-out;
    }
`;


