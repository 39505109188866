import styled, { keyframes } from 'styled-components';

export const LoadingContainer = styled.div`
  width: 100%;
  height:100%;
  display: flex;
  justify-content:center;
  align-items:flex-start ;
`;

export const UserListContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const isRotating = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingComp = styled.div`
  width: 70px;
  height: 70px;
  border: 6px solid #e5e5e5;
  border-top-color: #51d4db;
  animation: ${isRotating} 1s infinite;
  border-radius: 50%;
  margin-top: 100px;
`;
