import { PasswordInput, SimpleForm, TextInput } from 'react-admin';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const EditContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
`;

export const UserEditContainer = styled.div``;

export const UserEditForm = styled(SimpleForm)`
  @media screen and (max-width: 600px){
    margin: 10px 0 50px 0;
  }
`;

export const UserEditText = styled(TextInput)`
  width: 100%;
`;

export const UserEditPassword = styled(PasswordInput)`
  width: 100%;
`;

export const UserEditButton = styled.button`
  width: 180px;
  height:30px;
  border-radius: 6px;
  outline: none;
  border: 0;
  cursor: pointer;
  /* background-color: #D1382E; */
  background-color: ${({background}) => background};
  color: #fff;
  transition: 0.2s ease-in-out;
  margin-right: 10px;

  &:hover{
    opacity: 0.8;
    transition: 0.2s ease-in-out;
  }
`;

export const UserEditSwtichGrid = styled.div`
  position: relative;
  bottom: 0;
  border: 1px solid trans;
  z-index: 999;
  height: 40px;
  width: 40px;
  padding: 5px;
  cursor: default;
`;

export const UserEditDiv = styled.div`
  display: flex;
  justify-content:center;
  align-items:center;
  cursor: pointer;
`;

export const UserEditSpan = styled.span``;

export const BackButton = styled(Link)`
    width: 100px;
    height:40px;
    margin-left: 15px;
    border-radius: 4px;
    background-color: #2196f3;
    color: #fff;
    display: flex;
    justify-content:center;
    align-items:center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 15px;
    transition: 0.2s ease-in-out;

    &:hover{
      opacity: 0.7;
      transition: 0.2s ease-in-out;
    }
`;

