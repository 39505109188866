import React from 'react'
import { BooleanField, BooleanInput, Button, CreateButton, Datagrid, downloadCSV, EditButton, EmailField, ExportButton, FilterButton, FunctionField, List, SelectInput, TextField, TextInput, TopToolbar, usePermissions, useRefresh } from 'react-admin' //eslint-disable-line
import jsonExport from 'jsonexport/dist';

import {
  BadgeList
} from './UserList.Elements';
import { UserActions, UserSelectors } from '../store/reducer'; //eslint-disable-line
import { useDispatch, useSelector } from 'react-redux'; //eslint-disable-line
import PageNotFound from 'src/components/PageNotFound'; //eslint-disable-line
import { useTranslation } from 'react-i18next';
import Loading from 'src/components/Loading';
import { useGetPermissions } from 'src/hooks/usePermissions';
import { ContainerFilter, ContainerItem, ContainerLabel } from 'src/components/StyleFilters/StyleFilters.Elements';

const exporter = users => {
  const usersForExport = users.map(user => { //eslint-disable-line
      const { name, email, roles, ...userForExport } = user; // omit backlinks and author
      userForExport.name = name;
      userForExport.email = email;
      userForExport.roles = {...roles};

      return userForExport;
  });
  jsonExport(usersForExport, {
      headers: ['id', 'name', 'email'] // order fields in the export
  }, (err, csv) => {
      downloadCSV(csv, 'users'); // download as 'posts.csv` file
  });
};



const UserList = () => {
  const refresh = useRefresh();
  const permissions = useSelector(UserSelectors.getPermissions);
  const loading = useSelector(UserSelectors.getLoading);
  const {t} = useTranslation();
  const dispatch = useDispatch()
  dispatch(UserActions.endUserLoading());

  const handlePermissions = useGetPermissions();

  React.useEffect(() => {
    refresh();
    if (permissions.length === 0) {
      handlePermissions();
    } else {
      return
    }
  }, []);


  const userFilters = [
      <FunctionField source='email' label={t('User.email')} render={() => (
          <ContainerFilter>
              <ContainerLabel>{t('User.email')}</ContainerLabel>
              <ContainerItem>
                  <TextInput source="email" label={t('User.email')} />
              </ContainerItem>
          </ContainerFilter>
    )} />,
      <FunctionField source='name' label={t('User.name')} render={() => (
          <ContainerFilter>
              <ContainerLabel>{t('User.name')}</ContainerLabel>
              <ContainerItem>
                  <TextInput source="name" label={t('User.name')} />
              </ContainerItem>
          </ContainerFilter>
  )} />,
      <FunctionField source='active' label={t('User.Status')} render={() => (
          <ContainerFilter>
              <ContainerLabel>{t('User.status')}</ContainerLabel>
              <ContainerItem>
                  <SelectInput emptyText={t('User.chooseOption')} source="active" label={t('User.status')} choices={[
                      { id: true, name: t('User.active') },
                      { id: false, name: t('User.Inactive') },
                    ]} />
              </ContainerItem>
          </ContainerFilter>
)} />,
];

const ListActions = () => {
return(
    <TopToolbar>
        <FilterButton label="Adicionar filtro" />
        {permissions.includes('users:create') && <CreateButton />}
        <ExportButton />
    </TopToolbar>
)
}

const rowClick = (id, basePath, record) => {
  if (record.commentable && permissions.includes('users:details')) {
      return 'show';
  }
  return 'edit';
};


  return (
      <div>
          {loading ? <Loading /> : permissions.includes('users:list') ? <List actions={<ListActions />} exporter={exporter} filters={userFilters}>
              <Datagrid rowClick={permissions.includes('users:details') ? rowClick : ''}>
                  <TextField label={t('User.name')} source="name" />
                  <EmailField source="email" />
                  <FunctionField label={t('User.status')} render={record => record.active ? <BadgeList background="green">{t('User.active')}</BadgeList> : <BadgeList background="red">{t('User.Inactive')}</BadgeList>} />
                  {permissions.includes('users:details') && <EditButton label={permissions.includes('users:edit') ? 'Editar' : 'Mostrar'} />}
              </Datagrid>
          </List> :
          <PageNotFound /> }
      </div>
  )
}

export default UserList
