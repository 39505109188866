import {routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import RootReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();
const browserHistory = createBrowserHistory();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const Store = createStore(
  RootReducer(browserHistory),
  composeEnhancer(
    applyMiddleware(
      thunk,
      routerMiddleware(browserHistory),
      sagaMiddleware,
    ),
  ),
);

export default Store;

export {browserHistory};
