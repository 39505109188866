import axios from 'axios';
import {useCallback, useEffect, useState} from 'react' //eslint-disable-line
import { useDispatch } from 'react-redux';
import { DashboardActions } from 'src/pages/Dashboard/store/reducer';

export function useDashboard(day, month){
const dispatch = useDispatch();
const token = localStorage.getItem("token");

const handleDashboard = useCallback(async () => {
dispatch(DashboardActions.initLoading());
try{

  const config = {
    headers: {
      'Authorization': 'Bearer ' + token
    },
    params: { month: month, day: day}
  }
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/transactions/dashboard`, config);
  dispatch(DashboardActions.asyncDashboard(response?.data));
  dispatch(DashboardActions.endLoading());
  return null;
} catch(error){
  dispatch(DashboardActions.endLoading());
}
}, [day, month])

return handleDashboard
}