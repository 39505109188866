import React from 'react'

import {
  InputContainer,
  InputTitle,
  InputMain,
  Icon,
  InputFormat,
  InputSection
} from './Input.Elements'



const Input = ({
  title = '',
  icon, 
  iconSvg,  
  handleSetValue,
  handleValue,
  ...props
}) => { 
  return (
      <InputMain>
          <InputFormat icon={icon}>
              <InputSection>
                  <InputTitle>{title}</InputTitle>
                  <InputContainer {...props} />
              </InputSection>
              {icon && <Icon onClick={() => handleSetValue(!handleValue)} src={iconSvg} />}
          </InputFormat>
      </InputMain>
  )
}

export default Input