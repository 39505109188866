import styled from 'styled-components'

export const HeaderContainer = styled.header`
  width: 100%;
  margin: 20px 0 0 0;
  margin-left: 10px;
`;


export const HeaderSpan = styled.span`
  font-size: 30px;
  font-weight: bold;
`;
