import styled, { keyframes } from 'styled-components'

export const Logo = styled.img`
    width: 200px;
    height: 200px;
    background-color: none;
    /* margin-top: ${({isMobile}) => isMobile ? '-200px' : '-100px'}; */
    margin-top: -120px;
`;

export const InputPassword = styled.div`
    width: 100%;
    position: relative;
    margin: 20px 0;
`;

export const LoginContainer = styled.section`
  width: 100%;
  height: 100vh;
  background-color: #F2F2F2;
  display: flex;
  justify-content:center;
  align-items:center;
  border-top: 8px solid #92c437;
`;

export const LoginContent = styled.div`
  width: 500px;
  height:70vh;
  border-radius: 20px;
  margin-top: 30px;
  background-color: #fff;
  display: flex;
  justify-content:center;
  align-items:center;
`;

export const FormLogin = styled.form`
  width: 70%;
  height:100%;
`;

export const FormLoginContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
`;

export const FormLoginTitle = styled.h1`
  font-size: 45px;
  font-weight: normal;
  font-family: 'Cambria', sans-serif;
  color: #445278;
  margin: 0px 0 20px 0;
`;


export const LogoBackground = styled.img`
  width: 100%;
  height: 100%;
  background-position: center;
  background-size:cover ;
`;

export const BackImg = styled.img`
  width: 150px;
  height: 150px;
  background-position: center;
  background-size: cover;
`;



const isRotating = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingCircle = styled.div`
  width: 10px;
  height: 10px;
  border: 6px solid #e5e5e5;
  border-top-color: #51d4db;
  animation: ${isRotating} 1s infinite;
  border-radius: 50%;
  margin-right: 10px;
`;

// export const LoadingComp = styled.div`
//   width: 70px;
//   height: 70px;
//   border: 6px solid #e5e5e5;
//   border-top-color: #51d4db;
//   animation: ${isRotating} 1s infinite;
//   border-radius: 50%;
//   margin-top: 100px;
// `;
