import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import ptbr from '../src/i18n/pt-br';

const resources = {
  'pt-BR': {
    translation: ptbr,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'pt-BR',

    keySeparator: '.',

    interpolation: {
      escapeValue: false,
    },
  });

document.documentElement.lang = i18n.language;

i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
});

export default i18n;
