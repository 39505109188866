import * as React from "react";
import { fetchUtils, Admin, Resource, UserMenu, AppBar, Layout } from 'react-admin'; //eslint-disable-line
import UserIcon from '@mui/icons-material/Group';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Dashboard from "./pages/Dashboard";
import GlobalStyle from "./GlobalStyles";
import MyLoginPage from "./pages/LoginPage";
import Auth from "./pages/Auth";
import UserList from "./pages/User/UserList/UserList";
import UserEdit from "./pages/User/UserEdit/UserEdit";
import UserCreate from "./pages/User/UserCreate/UserCreate";
import jsonServerProvider from 'ra-data-json-server';
import TransactionList from "./pages/Transactions/TransactionsList/TransactionList";
import { useTranslation } from "react-i18next";
import MyLogoutButton from "./components/MyLogoutButton";
import TransactionsShow from "./pages/Transactions/TransactionsShow/TransactionsShow";
import * as dayjs from 'dayjs';
// import 'dayjs/locale/en';
// dayjs.locale('en')
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const apiUrl = process.env.REACT_APP_API_URL;

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    const token = localStorage.getItem("token");

    if (token) {
        options.headers.set("Authorization", `Bearer ${token}`);
    }

    return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(apiUrl, httpClient);

const MyUserMenu = () => (
    <UserMenu>
        <MyLogoutButton />
    </UserMenu>
);

const MyAppBar = () => (
    <AppBar userMenu={<MyUserMenu />} />
);

const MyLayout = props => (
    <Layout {...props} appBar={MyAppBar} />
);

const App = () => {
const {t} = useTranslation();
return(
    <>
        <GlobalStyle />
        <Admin layout={MyLayout} loginPage={MyLoginPage} dashboard={Dashboard} authProvider={Auth} dataProvider={dataProvider}>
            <Resource options={{ label: t('Main.users') }} name="users" list={UserList} edit={UserEdit} create={UserCreate} icon={UserIcon} />
            <Resource options={{ label: t('Main.transactions') }} name="transactions" list={TransactionList} edit={TransactionsShow} icon={AttachMoneyIcon} />
        </Admin>
    </>
)};

export default App;

