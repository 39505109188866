export const TransactionActionTypes = {
  ASYNC_TRANSACTION: '@auth/ASYNC_TRANSACTION',
  ASYNC_SELLERS: '@auth/ASYNC_SELLERS',
};

const INITIAL_STATE = {
  transaction: '',
  sellers: [],
};

export class TransactionActions {

  static asyncTransaction = (payload) => ({type: TransactionActionTypes.ASYNC_TRANSACTION, payload: payload});
  static asyncSellers = (payload) => ({type: TransactionActionTypes.ASYNC_SELLERS, payload: payload});
}

export class TransactionSelectors {

  static getTransaction({user}) {
    return user.transaction;
  }

  static getSellers({transaction}) {
    return transaction.sellers;
  }


}

export function reducer(state = INITIAL_STATE, {type, payload}) {
  switch (type) {
  case TransactionActionTypes.ASYNC_TRANSACTION:
    return {...state, transaction: payload};
  case TransactionActionTypes.ASYNC_SELLERS:
    return {...state, sellers: payload};
  default:
    return state;
  }
}
