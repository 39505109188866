import styled from 'styled-components';

export const BadgeList = styled.span`
  width: 100%;
  background-color: ${({background}) => background};
  padding: 3px 10px;
  border-radius: 10px;
  color: #fff;
  opacity: 0.7;
`;

export const StatusContainer = styled.div`
  background-color: #f0f0f1; 
  padding: 0px 10px;
  border-radius: 20px; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
  margin-bottom: 5px; 
  margin-left: 6px;
`;

export const StatusTitle = styled.span`
  margin-right: 10px;
`;

