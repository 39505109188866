import React, { useEffect, useState } from "react";
import {
  CheckboxGroupInput,
  Confirm,
  Edit,
  required,
  SaveButton,
  Toolbar,
  useRecordContext,
  useUpdate,
  useRedirect,
  useNotify,
  BooleanInput,
} from "react-admin";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import HeaderComponents from "src/components/HeaderComponents";
import { AuthSelectors, UserActions, UserSelectors } from "../store/reducer"; //eslint-disable-line

import {
  UserEditContainer,
  UserEditText,
  UserEditPassword,
  UserEditForm,
  UserEditSwtichGrid,
  UserEditDiv,
  UserEditSpan,
  BackButton
} from "./UserEdit.Elements";

const UserTitle = () => {
  const record = useRecordContext();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(UserActions.asyncUserEdit(record));
  }, []);

  return <span>User Edit</span>;
};

const UserEdit = () => {
  const { t } = useTranslation();
  const userEdit = useSelector(UserSelectors.getUserEdit);
  const userData = userEdit;
  const permissions = useSelector(UserSelectors.getPermissions);

  const UserEditToolbar = () => {
    return (
        <Toolbar>
            {permissions.includes('users:edit') ? <SaveButton style={{backgroundColor: '#2196f3'}}
                alwaysEnable
                label="Salvar Edição"
            />
            
            :
            <SaveButton
                label="Salvar Edição"
            />}
            <BackButton to="/users">{t('Transactions.back')}</BackButton>
        </Toolbar>
    );
};

    const [open, setOpen] = useState(false);
    const [boolean, setBoolean] = useState(userData?.active)
    const notify = useNotify();


    const [update, { error }] = useUpdate();
    const redirect = useRedirect();

    useEffect(() => {
      setBoolean(userData?.active);
    }, [userData?.active]);


    const handleClick = () => {
      if(!permissions.includes('users:enable')){
        return;
      }

      if(boolean === true){
        setOpen(true);
      } else  {
        notify(t('User.NotifyEnable'));
      }
      setBoolean(true);
    }
    const handleDialogClose = () => {
      setOpen(false);
    }
    const handleConfirm = () => {
        setBoolean(false)
        setOpen(false);
        notify(boolean ? t("User.NotifyDisable") : t("User.NotifyEnable"));
    };


    const UserEDit = (data) => {
      data = {
        ...data,
        active: boolean ? true : false
      }

      update('users', { id: userData?.id, data: data, previousData: userData })
      if(error){
        toast.error('Erro ao editar o usuário', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          })
        console.log('deu erro', error);
      } else {
        redirect('/users');
        toast.success('Usuário editado com sucesso!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          })
      }
    };


  return (
      <UserEditContainer>
          <HeaderComponents title='Editar Usuário' />
          {permissions.includes('users:details') && <Edit title={<UserTitle />}>
              <UserEditForm onSubmit={UserEDit} toolbar={<UserEditToolbar />}>
                  <UserEditText
                      validate={required(t("form.RequiredField"))}
                      label="Nome"
                      source="name"
                      disabled={!permissions.includes('users:edit')}
                  />
                  <UserEditPassword
                      validate={required(t("form.RequiredField"))}
                      label="Senha"
                      source="password"
                      disabled={!permissions.includes('users:edit')}
                  />

                  <CheckboxGroupInput label={t('User.permissionsUser')} source="roles" disabled={!permissions.includes('users:edit')} choices={[
                    { id: 'users:list', name: t('User.userList') },
                    { id: 'users:details', name: t('User.userDetails') },
                    { id: 'users:create', name: t('User.userCreate') },
                    { id: 'users:edit', name: t('User.userEdit') },
                    { id: 'users:enable', name: t('User.userEnable') },
                  ]} />

                  <CheckboxGroupInput label={t('Transactions.permissionsTransactions')} source="roles" disabled={!permissions.includes('users:edit')} choices={[
                    { id: 'transactions:list', name: t('Transactions.transactionsList') },
                    { id: 'transactions:details', name: t('Transactions.transactionsDetails') },
                    { id: 'transactions:reversal', name: t('Transactions.transactionsEstorno') },
                  ]} />

                  <CheckboxGroupInput label={t('Clients.permissionsClients')} source="roles" disabled={!permissions.includes('users:edit')} choices={[
                    { id: 'clients:list', name: t('Clients.permissionsClients') },
                  ]} />

                  <CheckboxGroupInput label={t('Sellers.permissionsSellers')} source="roles" disabled={!permissions.includes('users:edit')} choices={[
                    { id: 'sellers:list', name: t('Sellers.sellersList') },
                    { id: 'sellers:details', name: t('Sellers.sellersDetails') },
                  ]} />

                  {permissions.includes('users:enable') && <UserEditDiv onClick={() => permissions.includes('users:edit') && handleClick()}>
                      <UserEditSwtichGrid>
                          <BooleanInput disabled={!permissions.includes('users:edit')} source="active" label='' value={boolean} checked={boolean} />
                      </UserEditSwtichGrid>
                      <UserEditSpan>{boolean ? t("User.DisableUser") : t("User.EnableUser")}</UserEditSpan>
                  </UserEditDiv>}

                  {boolean === true && <Confirm
                      isOpen={open}
                      title={boolean ? t("User.DisableUser") : t("User.EnableUser")}
                      content={boolean ? t("User.DisableMessage") : t("User.EnableMessage")}
                      onConfirm={handleConfirm}
                      onClose={handleDialogClose}
                    />}
              </UserEditForm>
          </Edit>}
      </UserEditContainer>
  );
};

export default UserEdit;
