import styled from 'styled-components';

export const InputContainer = styled.input`
    width: 400px;
    height: 40px;
    outline: none;
    border: solid 1px #bcc4ce;
    border-radius: 20px;
    margin-bottom: ${({marginB}) => `${marginB}px`};
    color: #5b5858;
    font-size: 16px;
    padding-left: 10px;
    padding: 10px 0 10px 10px;

    &:focus{
        border: solid 1px #7086C4;
    }
`;

export const InputTitle = styled.div`
    margin: 3px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #505367;
    font-size: calc(10px + (12 - 10) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;
    text-transform: uppercase;
`;

export const InputMain = styled.main`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 400px;
`;

export const InputFormat = styled.div`
    width: 100%;
    position: ${({icon}) => icon ? 'relative' : ''};
`;

export const Icon = styled.img`
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: absolute;
    top: 65%;
    right: 10px;
    transform: translateY(-50%);
`;

export const InputSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;
