import React from 'react'
import { Datagrid, DateField, DateInput, downloadCSV, ExportButton, FilterButton, TextField, FunctionField, List, SelectInput, TextInput, TopToolbar, useRefresh } from 'react-admin' //eslint-disable-line
import jsonExport from 'jsonexport/dist';
import { useSelector } from 'react-redux'; 
import { UserSelectors } from 'src/pages/User/store/reducer';
import PageNotFound from 'src/components/PageNotFound';
import { useTranslation } from 'react-i18next';
import Loading from 'src/components/Loading';
import { toCurrency } from 'src/utils/formatters';
import { useGetPermissions } from 'src/hooks/usePermissions';
import { useStatus } from 'src/hooks/useStatus';
import { useGetSelers } from 'src/hooks/useClients';
import { TransactionSelectors } from '../store/reducer';
import dayjs from 'dayjs';
import {
  ContainerFilter,
  ContainerLabel,
  ContainerItem
} from '../../../components/StyleFilters/StyleFilters.Elements'

const TransactionList = () => {
const permissions = useSelector(UserSelectors.getPermissions); 
const {t} = useTranslation();
const loading = useSelector(UserSelectors.getLoading);
const getStatus = useStatus();
const handleSelers = useGetSelers();
const sellers = useSelector(TransactionSelectors.getSellers); //eslint-disable-line

const sellersArray = [];
sellers.map(seller => {
sellersArray.push({
  id: seller,
  name: seller
})
});

const refresh = useRefresh();

const ListActions = () => {
return(
    <TopToolbar>
        <FilterButton />
        <ExportButton />
    </TopToolbar>
  )
}

const handlePermissions = useGetPermissions();
  React.useEffect(() => {
  handleSelers();
  refresh();
  if (permissions.length === 0) {
    handlePermissions();
  } else {
  return
}
}, []);
  const TransFilters = [
      <FunctionField alwaysOn source='id' label={t('Transactions.transactionId')} render={() => (
          <ContainerFilter>
              <ContainerLabel>{t('Transactions.transactionId')}</ContainerLabel>
              <ContainerItem>
                  <TextInput source="id" label={t('Transactions.transactionId')} alwaysOn />
              </ContainerItem>
          </ContainerFilter>
      )} />,
      <FunctionField source='sellerId' label={t('Transactions.sellerId')} render={() => (
          <ContainerFilter style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', marginTop: '15px'}}>
              <ContainerLabel>{t('Transactions.sellerId')}</ContainerLabel>
              <ContainerItem style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <TextInput defaultValue={null} source="sellerId" label={t('Transactions.sellerId')} />,
              </ContainerItem>
          </ContainerFilter>
      )} />,
      <FunctionField source='transactionType' label={t('Transactions.transactionType')} render={() => (
          <ContainerFilter style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', marginTop: '15px'}}>
              <ContainerLabel>{t('Transactions.transactionType')}</ContainerLabel>
              <ContainerItem style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <SelectInput emptyText={'Escolha uma opção'} source="transactionType" label={t('Transactions.transactionType')} choices={[
                      { id: 'boleto', name: t('Transactions.boleto') },
                      { id: 'pix', name: t('Transactions.pix') },
                      { id: 'lio', name: t('Transactions.lio') },
                      { id: 'cartao', name: t('Transactions.onlineCard') },
                  ]} />
              </ContainerItem>
          </ContainerFilter>
      )} />,
      <FunctionField source='salesChannel' label={t('Transactions.salesChannel')} render={() => (
          <ContainerFilter style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', marginTop: '15px'}}>
              <ContainerLabel>{t('Transactions.salesChannel')}</ContainerLabel>
              <ContainerItem style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <SelectInput emptyText={'Escolha uma opção'} source="salesChannel" label="Canal de Venda" choices={sellersArray} />
              </ContainerItem>
          </ContainerFilter>
    )} />,
      <FunctionField alwaysOn source='created_at_begin' label={t('Data de Criação')} render={() => (
          <ContainerFilter style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', marginTop: '15px'}}>
              <ContainerLabel>Data de Criação</ContainerLabel>
              <ContainerItem style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <DateInput source="created_at_begin" label="Data Inicio" />
                  <span style={{margin: '0 20px'}}> to </span>
                  <DateInput source="created_at_end" label="Data Fim" />
              </ContainerItem>
          </ContainerFilter>
      )} />,
      <FunctionField alwaysOn source='payment_at' label={t('Data do Pagamento')} render={() => (
          <ContainerFilter style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', marginTop: '15px'}}>
              <ContainerLabel>Data de Pagamento</ContainerLabel>
              <ContainerItem style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <DateInput source="payment_at_begin" label="Data Inicio" />
                  <span style={{margin: '0 20px'}}> to </span>
                  <DateInput source="payment_at_end" label="Data Fim" />
              </ContainerItem>
          </ContainerFilter>
      )} />,
];

const rowClick = (record) => {
  if (record.commentable) {
    return 'edit';
  }
    return 'show';
};

  const exporter = transactions => { 
  const transactionForExport = transactions.map(transaction => {
  const { ...userForExport } = transaction; // omit backlinks and author

  return userForExport;
  });
  jsonExport(transactionForExport, {
  headers: ['id', 'name', 'email'] // order fields in the export
  }, (err, csv) => {
  downloadCSV(csv, 'transactions'); // download as 'posts.csv` file
  });
  };

return (
    <div>
        {loading ? <Loading /> : permissions.includes("transactions:list") ? <List actions={<ListActions />} exporter={exporter} filters={TransFilters}>
            <Datagrid rowClick={permissions.includes('transactions:details') ? rowClick : ''}>
                <TextField label={t('Transactions.transactionId')} source="id" />
                <FunctionField sortBy={'amount'} label={t('Transactions.amount')} render={record => record.amount ? toCurrency(record?.amount) : '' } />
                {/* <DateField label={t('Transactions.created_at')} source="created_at" /> */}
                <FunctionField sortBy={'created_at'} label={t('Transactions.created_at')} render={record => record.created_at ? dayjs(record?.created_at).utc(false).format('DD/MM/YYYY') : '' } />
                <DateField label={t('Transactions.dateUpdated')} source="updated_at" />
                <FunctionField sortBy={'payment_at'} label={t('Transactions.payment_at')} render={record => record.payment_at ? dayjs(record?.payment_at).utc(false).format('DD/MM/YYYY') : '' } />
                <FunctionField sortBy={'status'} label={t('Transactions.status')} render={
                    record => record.status && <span>{getStatus(record.status)}</span> }
                  />
                <TextField label={t('Transactions.sellerId')} source="sellerId" />
                <TextField label={t('Transactions.salesChannel')} source="salesChannel" />
                {/* <TextField label={t('Transactions.transactionType')} source="transactionType" /> */}
                <FunctionField sortBy={'transactionType'} label={t('Transactions.transactionType')} render={record => record.transactionType === 'cartao' ? 'Cartão Online' : record.transactionType} />
            </Datagrid>
        </List> : <PageNotFound />}
    </div>
)
}

export default TransactionList