import styled from 'styled-components';

export const PNFContainer = styled.section`
  width: 98%;
  height:90vh;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  background-color: #fff;
  margin-left: 20px;
  border-radius: 6px;
  box-shadow: 0 0 0.2em black;
`;

export const PNFTitle = styled.h3`
  text-transform: uppercase;
  font-size: 25px;
  text-align: center;
`;

export const PNFImg = styled.img`
  width:700px;
  height: 700px;
  background-position:center;
  background-size: cover;
`;
