import { FunctionField, TextField } from 'react-admin';
import styled from 'styled-components';
import { Box, TableCell } from '@mui/material';
import { Link } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export const TitleField = styled.span`
  margin-top: 100px;
  font-weight: 400;
  font-size: 14px;

`;

export const TransShowText = styled(TextField)`
  padding: 15px;
  display: flex;
  justify-content:flex-start;
  align-items:center;
  border-radius: 6px;
  border-bottom: 1px solid #ccc;
  border-radius: 8px 8px 0 0;
  transition: 0.2s ease-in-out;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TransBox = styled(Box)`
  margin: ${({ margin }) => margin};
`;

export const TransTypography = styled.h5`
  font-size: 25px;
  text-align: center;
  margin: 15px 0;
`;

export const TransFunction = styled(FunctionField)`
  padding: 15px;
  display: flex;
  justify-content:flex-start;
  align-items:center;
  border-radius: 6px;
  border-bottom: 1px solid #ccc;
  border-radius: 8px 8px 0 0;
  transition: 0.2s ease-in-out;
  font-size: 20px;
`;

export const TransSpan = styled.span`
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TransLink = styled(Link)`
  width: 100px;
  height:50px;
  border-radius: 8px;
  background-color: #2196f3;
  color: #fff;
  display: flex;
  justify-content:center;
  align-items:center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 15px;
  transition: 0.2s ease-in-out;

  &:hover{
    opacity: 0.7;
    transition: 0.2s ease-in-out;
  }

  @media screen and (max-width: 1220px){
    margin-left: 15px;
  }
`;


export const EstornoContainer = styled.section`
  display: flex;
  justify-content:center;
  align-items:center;
  flex-direction: column;
`;

export const EstornoTitle = styled.span`
  font-size: 25px;
`;

export const EstornoInput = styled.input`
  margin: 10px 0 15px 0;
  width: 150px;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #ccc;

`;

export const EstornoButtons = styled.div`
  display: flex;
  justify-content:center;
  align-items:center;
`;

export const EstornoButtonConfirm = styled.button`
  margin-right: 10px;
  width: 100px;
  height: 35px;
  border-radius: 8px;
  background-color: green;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover{
    opacity: 0.8;
    transition: 0.2s ease-in-out;
  }

`;

export const EstornoButtonCancel = styled.button`
  margin-right: 10px;
  width: 100px;
  height: 35px;
  border-radius: 8px;
  background-color: tomato;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover{
    opacity: 0.8;
    transition: 0.2s ease-in-out;
  }
`;

export const Container = styled.div`
  display: flex; 
  width: 100%; 
  height: 100%; 
  justify-content: flex-start; 
  align-items: center;
  margin: 0px 20px;
`;

export const History = styled.div`
  display: none;
  margin: 0 10px;
  text-align: center;
  width: 100%;
  height: 200px;
  margin-bottom: ${({marginBottom}) => `${marginBottom * 50}px`};

  @media screen and (max-width: 1200px){
    display: block;
  }

  @media screen and (max-width: 600px){
    margin-bottom: ${({marginBottom}) => `${marginBottom * 60}px`};
  }
`;

export const HistoryContainer = styled.div`
  width: 97.5%;
  height: 150px;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
`;

export const TableCellRight = styled(TableCell)({ textAlign: 'right' });

export const HistoryDate = styled.div`
  display: flex; 
  justify-content: flex-start; 
  align-items: center;
`;

export const IconDate = styled(AccessTimeIcon)`
  margin-right: 5px;
`;

export const HistoryContent = styled.div`
  display: flex; 
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: flex-start;
  margin: 0 10px;

  @media screen and (max-width: 522px){
    justify-content: center; 
    align-items: center;
  }
`;

export const HistorySpan = styled.span`
  font-size: 14px;
  @media screen and (max-width: 522px){
    margin-bottom: 10px;
  }
`;

export const HistoryButtons = styled.div`
  display: none;
  width: 100%;
  margin-bottom: 0px;

  @media screen and (max-width: 1200px){
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 600px){
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 90px;
  }
`;

export const EstornoTotal = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 8px;
  background-color: tomato;
  display: flex;
  justify-content:center;
  align-items:center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 15px;
  transition: 0.2s ease-in-out;
  outline: none;
  border:none;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  margin-bottom: 10px;

  &:hover{
    opacity: 0.7;
    transition: 0.2s ease-in-out;
  }
`;

export const Line = styled.div`
  width: 3px; 
  height: ${({height}) => `${height * 70}px`};
  background-color: #ccc; 
  margin-right: 0px;
  margin-left: 20px;

  @media screen and (max-width: 1535px){
    height: ${({height}) => `${height * 100}px`};
  }

  @media screen and (max-width: 400px){
    height: ${({height}) => `${height * 100}px`};
  }
`;

export const Seta = styled.div`
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #ccc;
  margin-right: 10px;
`;

export const Date = styled.span`
  
`;

export const Status = styled.span`
  font-size: 15px;
`;

export const TimeLine = styled.div`
  display: flex; 
  justify-content:center; 
  align-items: center;
`;

export const Dates = styled.div`
  margin-right: 40px;
`;

export const StatusBody = styled.div`
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: flex-start;
`;

export const HistoryResponsiva = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 6px;

  display: none;

  @media screen and (max-width: 1200px){
    display: block
  }

`;

export const Border = styled.div`
  width: 100%;
  height: 15px;
  background-color: #FAFAFB;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
`;

export const ToolbarContainer = styled.div`
  display: block;

  @media screen and (max-width: 1220px){
    display: none;
  }
`;