import React from 'react'

import {
  PNFContainer,
  PNFTitle,
  PNFImg
} from './PageNotFound.Elements'
import Access from '../../assets/images/access.svg'
import { useTranslation } from 'react-i18next';

const PageNotFound = () => {
  const {t} = useTranslation();
  return (
      <PNFContainer>

          <PNFTitle>{t('PageNotFound.title')}</PNFTitle>
          <PNFImg src={Access} />
      </PNFContainer>
  )
}

export default PageNotFound
