import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_PERMISSIONS } from 'admin-on-rest';

const API_URL = process.env.REACT_APP_API_URL;


export default (type, params) => {

  if (type === AUTH_LOGIN) {
    const grant_type = 'password';
    const { username, password } = params;
    const request = new Request(`${API_URL}/auth/token`, {
    method: 'POST',
    body: JSON.stringify({ username, password, grant_type }),
    headers: new Headers({ 'Content-Type': 'application/json' }),
    })
    return fetch(request)
    .then(response => {
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return response.json();
  })
  .then((res) => {
    localStorage.setItem('token', res.access_token);
  });
  }


  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('token');
    return Promise.resolve();
  }

  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  }

  if (type === AUTH_CHECK) {
   return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  }

  if (type === AUTH_PERMISSIONS){
    () => {
      const role = localStorage.getItem('permissions');
      return role ? Promise.resolve(role) : Promise.reject();
  }
  }



  return Promise.resolve();
}
