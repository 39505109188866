import * as React from 'react';
import Input from 'src/components/Input';
import Visible from '../../assets/icons/Visible.svg'
import Invisible from '../../assets/icons/Invisible.svg'
import {
  LoginContainer,
  LoginContent,
  FormLogin,
  FormLoginContent,
  // FormLoginTitle,
  Logo,
  LoadingCircle
} from './LoginPage.Elements'
import { useLogin, useRedirect } from 'react-admin';
import Button from 'src/components/Button';
import LogoLogin from '../../assets/images/LOGO-ADMIN-CISPAY.svg'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions, UserSelectors } from '../User/store/reducer';
import { toast } from 'react-toastify';

const MyLoginPage = () => {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const redirect = useRedirect();
    const login = useLogin();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    
    const userLoading = useSelector(UserSelectors.getUserLoading); //eslint-disable-line

    const handleSubmit = e => {
        e.preventDefault();
        dispatch(UserActions.initUserLoading());
        login({ username: email, password }).then(() => toast.info('Seja bem-vindo', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            })
        ).then(() => redirect('/'))
      

    };

    return (
        <LoginContainer>
            <LoginContent>
                <FormLogin onSubmit={handleSubmit}>
                    <FormLoginContent>
                        <Logo src={LogoLogin} />

                        <Input
                            title={t('LoginPage.email')}
                            name="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            marginB={30}
                        />

                        <Input
                            title={t('LoginPage.password')}
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            icon={true}
                            iconSvg={showPassword ? Invisible : Visible}
                            handleSetValue={setShowPassword}
                            handleValue={showPassword}
                        />

                        {userLoading ? <Button loading={true} bgColor={'#00619E'} widthT={400} marginT={40} title={
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
                                <LoadingCircle /> carregando
                            </div>} /> 
                            : <Button loading={false} bgColor={'#00619E'} widthT={400} marginT={40} title={t('LoginPage.submit')} />
                            }
                   
                    </FormLoginContent>
                </FormLogin>
            </LoginContent>
        </LoginContainer>
    );
};

export default MyLoginPage;
