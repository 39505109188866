import React from 'react'

import {
  HeaderContainer,
  HeaderSpan
} from './Header.Elements'

const HeaderComponents = ({title}) => {
  return (
      <HeaderContainer>
          <HeaderSpan>{title}</HeaderSpan>
      </HeaderContainer>
  )
}

export default HeaderComponents
