import React from 'react'
import { MenuItem } from '@mui/material';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import { useLogout } from 'react-admin';
import { useDispatch } from 'react-redux';
import { UserActions } from 'src/pages/User/store/reducer';

const MyLogoutButton = () => {
  const logout = useLogout();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(UserActions.asyncPermissions([]));
    dispatch(UserActions.endUserLoading());
    logout();
  };
  return (
      <MenuItem
          onClick={handleClick}>
          <ExitIcon style={{marginRight: '5px'}} /> Logout
      </MenuItem>
  );
}

export default MyLogoutButton
