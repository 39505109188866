import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {Provider} from 'react-redux';
import {ToastContainer} from 'react-toastify';
import registerServiceWorker from './registerServiceWorker';

import Store from './store/store';

import './i18n';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={Store}>
            <App />
            <ToastContainer />
        </Provider>
    </React.StrictMode>
);

registerServiceWorker();
