import React from 'react'
import { CheckboxGroupInput, Create, required, SaveButton, Toolbar, useCreate, useRedirect } from 'react-admin'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import HeaderComponents from 'src/components/HeaderComponents'

import {
  UserCreateText,
  UserCreateGrid,
  UserCreatePassword,
  UserCreateGridInput,
  UserCreateForm,
  BackButton
} from './UserCreate.Elements'

const UserCreate = (props) => {
  const {t} = useTranslation();
  const [create, {error}] = useCreate(); //eslint-disable-line
  const redirect = useRedirect();

  const UserCreateToolbar = () => {
    return (
        <Toolbar>
            <SaveButton style={{backgroundColor: '#2196f3'}}
                alwaysEnable
                label="Criar Usuário"
            />
            <BackButton to="/users">{t('Transactions.back')}</BackButton>
        </Toolbar>
    );
};

  const handleCreate = ( data ) => {

    create('users', {data})
    if(error){
      console.log(error);
    } else {
      redirect('/users');
      toast.success('Usuário criado com sucesso!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        })
    }
  }

  return (
      <div>
          <HeaderComponents title='Criar Usuário' />
          <Create {...props}>
              <UserCreateForm toolbar={<UserCreateToolbar />} onSubmit={handleCreate}>
                  <UserCreateText validate={required(t('form.RequiredField'))} label="Nome" source="name" />
                  <UserCreateGrid>
                      <UserCreateGridInput>
                          <UserCreateText validate={required(t('form.RequiredField'))} label="Email" source="email" />
                      </UserCreateGridInput>
                      <UserCreatePassword validate={required(t('form.RequiredField'))} label="Senha" source="password" />
                  </UserCreateGrid>

                  <CheckboxGroupInput label={t('User.permissionsUser')} source="roles" choices={[
                    { id: 'users:list', name: t('User.userList') },
                    { id: 'users:details', name: t('User.userDetails') },
                    { id: 'users:create', name: t('User.userCreate') },
                    { id: 'users:edit', name: t('User.userEdit') },
                    { id: 'users:enable', name: t('User.userEnable') },
                  ]} />

                  <CheckboxGroupInput label={t('Transactions.permissionsTransactions')} source="roles" choices={[
                    { id: 'transactions:list', name: t('Transactions.transactionsList') },
                    { id: 'transactions:details', name: t('Transactions.transactionsDetails') },
                    { id: 'transactions:reversal', name: t('Transactions.transactionsEstorno') },
                  ]} />

                  <CheckboxGroupInput label={t('Clients.permissionsClients')} source="roles" choices={[
                    { id: 'clients:list', name: t('Clients.permissionsClients') },
                  ]} />

                  <CheckboxGroupInput label={t('Sellers.permissionsSellers')} source="roles" choices={[
                    { id: 'sellers:list', name: t('Sellers.sellersList') },
                    { id: 'sellers:details', name: t('Sellers.sellersDetails') },
                  ]} />
              </UserCreateForm>
          </Create>
      </div>
  )
}

export default UserCreate
