

export function useStatus() {
    const getStatus = (status) => {
      switch(status){
        case 0:
          return 'Não Finalizado';
        case 1:
          return 'Autorizado';
        case 2:
          return 'Pagamento Confirmado';
        case 3:
          return 'Negado';
        case 10:
          return 'Anulado';
        case 11:
          return 'Devolveu';
        case 12:
          return 'Pendente';
        case 13:
          return 'Abortado';
        
        default:
          return 'Sem Status';
      }
    };


  return getStatus;
}