export const DashboardActionTypes = {
        ASYNC_DASHBOARD: '@auth/ASYNC_DASHBOARD',
        INIT_LOADING: '@auth/INIT_USER_LOADING',
        END_LOADING: '@auth/END_USER_LOADING',
    };


    const INITIAL_STATE = {
        dashboard: {},
        loading: false,
    };


    export class DashboardActions {
        static asyncDashboard = (payload) => ({type: DashboardActionTypes.ASYNC_DASHBOARD, payload: payload});
        static initLoading = () => ({type: DashboardActionTypes.INIT_LOADING, payload: true});
        static endLoading = () => ({type: DashboardActionTypes.END_LOADING, payload: false});
    }
    export class DashboardSelectors {
        static getDashboard({dashboard}) {
        return dashboard.dashboard;
    }
    
    static getLoading({dashboard}) {
        return dashboard.loading;
    }
    }
    export function reducer(state = INITIAL_STATE, {type, payload}) {
        switch (type) {
        case DashboardActionTypes.ASYNC_DASHBOARD:
            return {...state, dashboard: payload};
        case DashboardActionTypes.INIT_LOADING:
            return {...state, loading: payload};
        case DashboardActionTypes.END_LOADING:
            return {...state, loading: payload};
        default:
        return state;
        }
    }