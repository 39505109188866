import { PasswordInput, SimpleForm, TextInput } from 'react-admin';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const UserCreateGrid = styled.div`
  width: 100%;
  display: flex;
  justify-content:space-between;
  align-items:center;

  @media screen and (max-width: 600px){
    flex-wrap: wrap;
  }
`;

export const UserCreateForm = styled(SimpleForm)`
  @media screen and (max-width: 600px){
    margin: 10px 0 50px 0;
  }
`;

export const UserCreateText = styled(TextInput)`
  width: 100%;
  border: 1px solid black;
`;


export const UserCreatePassword = styled(PasswordInput)`
  width: 100%;
  margin: 50px;
`;

export const UserCreateGridInput = styled.div`
  width: 100%;
  margin-right: 20px;

  @media screen and (max-width: 600px){
    margin-right: 0;
  }
`;

export const BackButton = styled(Link)`
    width: 100px;
    height:40px;
    margin-left: 15px;
    border-radius: 4px;
    background-color: #2196f3;
    color: #fff;
    display: flex;
    justify-content:center;
    align-items:center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 15px;
    transition: 0.2s ease-in-out;

    &:hover{
      opacity: 0.7;
      transition: 0.2s ease-in-out;
    }
`;


